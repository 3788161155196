<template>
    <h2 class="titleText">{{ content }}</h2>
</template>

<script>
export default {
    name: 'TitleText',
    props: {
        content: {
            type: String,
            required: true
        }
    }
}
</script>