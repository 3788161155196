<template>
    <div class="cardContainer">
        <img class="cardImage" :src="source" alt="image of cat">

        <div v-if="data" class="cardBody">
            <p class="cardMetaText">extra card data</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        source: {
            type: String,
            required: true
        },
        data: {
            type: Boolean,
            required: true
        }
    }
}
</script>