<template>
    <button class="primaryActionBtn">{{ meta }}</button>
</template>

<script>
export default {
    name: 'PrimaryActionBtn',
    props: {
        meta: {
            type: String,
            required: true
        }
    }
}
</script>