<template>
    <div class="sectionHeader">
        <TitleText :content="title" />
        <MetaText :content="meta"/>
    </div>
</template>

<script>
import TitleText from '@/components/Typography/TitleText.vue'
import MetaText from '@/components/Typography/MetaText.vue'

export default {
    name: 'SectionHeader',
    components: { TitleText, MetaText },
    props: {
        title: {
            type: String,
            required: true
        },
        meta: {
            type: String,
            required: false
        }
    }
}
</script>