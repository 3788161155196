<template>
    <p class="metaText">{{ content }}</p>
</template>

<script>
export default {
    name: 'MetaText',
    props: {
        content: {
            type: String,
            required: true
        }
    }
}
</script>