<template>
    <div class="infoCard">
        <TitleText content="Note!" />

        <div class="line"></div>

        <h4 class="subTitle">User Responsibility</h4>
        <p class="text">By using Catindr, you agree to use the app responsibly and in accordance with its educational purpose. Please avoid any actions that could harm, disrupt, or place an undue burden on the third-party API. Your cooperation is appreciated to keep the app running smoothly for all users.</p>

        <div class="line"></div>

        <h4 class="subTitle">Use of Third-Party API</h4>
        <p class="text">Catindr relies on a third-party API to provide its features and functionalities. Users must adhere to the terms and limitations of this API, ensuring they do not overload or misuse it. Responsible usage is essential to maintain the integrity and performance of the app.</p>

        <div class="line"></div>

        <h4 class="subTitle">Accuracy of Information</h4>
        <p class="text">Catindr is provided "as is" and is intended for educational purposes. While it aims to enhance knowledge about cats, the accuracy or completeness of the information is not guaranteed.</p>
    </div>
</template>

<script>
import TitleText from '@/components/Typography/TitleText.vue'

export default {
    components: { TitleText }
}
</script>