<template>
    <header>
        <h1 class="topTitle">Catindr</h1>
        <p class="topMeta">The purrrfect world</p>
    </header>
</template>

<script>
export default {
    name: 'Top'
}
</script>