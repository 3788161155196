import { createRouter, createWebHistory } from 'vue-router'
import TermsOfService from '@/views/TermsOfService.vue'
import PublicView from '@/views/PublicView.vue'
import MainView from '@/views/MainView.vue'

const routes = [
    {
        path: '/terms',
        name: 'termsOfService',
        component: TermsOfService
    },
    {
        path: '/',
        name: 'public',
        component: PublicView,
        meta: { requiresAuth: true }
    },
    {
        path: '/main',
        name: 'main',
        component: MainView,
        meta: { requiresAuth: true }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/terms'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const termsAccepted = localStorage.getItem('termsAccepted');
    if (to.matched.some(record => record.meta.requiresAuth) && !termsAccepted) {
        next('/terms');
    } else {
        next();
    }
});

export default router
