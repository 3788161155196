<template>
    <div class="termsLayout">
        <Top />
        <SectionHeader title="Welcome" meta="Before proceding to the main page please read through the note!"/>
        <div class="section">
            <InfoCard />
            <PrimaryActionBtn @click="acceptTerms" meta="Sure" />
        </div>
    </div>
</template>

<script>
import Top from '@/components/Top.vue';
import SectionHeader from '@/components/SectionHeader.vue';
import PrimaryActionBtn from '@/components/PrimaryActionBtn.vue'
import InfoCard from '@/components/InfoCard.vue';

export default {
    name:'TermsOfService',
    components: { Top, SectionHeader, PrimaryActionBtn, InfoCard },
    methods: {
        acceptTerms() {
            localStorage.setItem('termsAccepted', 'true');
            this.$router.push('/');
        }
    }
}
</script>